import img_tcmtp from "@assets/images/img_tcmtp.png";
import img_tcmtp_m from "@assets/images/img_tcmtp-m.png";

const SectionThree = () => {
  return (
    <>
      <section className="feature03" id="themePark">
        <div className="inner">
          <figure>
            <span className="pc">
              <img src={img_tcmtp} alt="" />
            </span>
            <span className="mobile">
              <img src={img_tcmtp_m} alt="" />
            </span>
          </figure>
          <strong className="tit">Metaverse Theme Park by Treasures Club</strong>
          <strong className="sub-tit">
            메타버스 테마파크 웹 3.0, 블록체인 기술, NFT IP를 활용한 메타버스 놀이터와 쇼핑몰,
            그리고 만남의 광장!
          </strong>
          <p>
            어렵고 재미없는 NFT, 결국 다 똑같아 보이는 메타버스 도대체 어쩔티비?! 대체 불가능한
            트레져스클럽 메타버스 테마파크, 트레져스유니버스에서 시나게 즐기면 웹 3.0 시대의 주인공이 되세요.
          </p>
          <p>
            트레져스클럽은 테마파크의 준공에 들어가기도 전에 토크노믹스 설계를 완료,
            솔라나 레이어 2 블록체인, TRGC를 이미 발행했습니다. 트레져스유니버스는
            TRGC의 강력한 활용처로써 유저들은 가상의 세상에서의 다양한 활동을 즐기면서 자연스럽게 가상 자산을 운용해 나갈 수 있게 됩니다.
            더하여서, 트레져스클럽은 자유로운 참여와 열린 경쟁, 그 웹 3.0의 법칙을 존중하기 때문에, 트레져스클럽의 NFT IP뿐만이 아니라
            타 IP의 게임 월드 또한 트클 메타버스 플랫폼 위, 다양한 개별 파크 혹은 월드 등의 다양한 형태로 끊임없이 런칭할 것입니다.
          </p>
          <p>
            이 모든 것이 홀더의 참여로 만들어지고 다채롭게 발전되는 트레져스클럽 메타버스 테마파크, Treasures Universe입니다!
          </p>
        </div>
      </section>
    </>
  );
};

export default SectionThree;

