// images
import section02_img from "@assets/images/img_section02.jpg";
import logo_metaverse_img from "@assets/images/logo_metaverse.png";
import img_ww01 from "@assets/images/img_ww01.png";
import img_ww02 from "@assets/images/img_ww02.png";
import img_ww03 from "@assets/images/img_ww03.png";
import img_ww04 from "@assets/images/img_ww04.png";
import img_section02_m from "@assets/images/img_section02-m.jpg";
import { pathName } from "../../common/routes";
import { HashLink } from "react-router-hash-link";
import NftContBox from "@components/NftContBox";
import { useCallback, useState } from "react";
import produce from "immer";
import { useRecoilState, useRecoilValue } from "recoil";
import { accountInfoAtom, isWalletLoginAtom } from "../../recoil/atom";
import { cursorPointerStyle } from "@styles/utils";

const SectionOne = () => {
  const { SEARCH } = pathName;

  const isWalletLogin = useRecoilValue(isWalletLoginAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);

  const [contBoxLists, setContBoxLists] = useState([
    {
      img: img_ww01,
      liClassName: "cont-box01",
      divClassName: "cont01",
      title: "도시어부",
      desc: (
        <p>
          도시어부 NFT는 현재 낚시하는 바다에 신비한 효과를 부여 합니다. 물론 그중 더 경이로운
          효과를 부여하는 NFT도 있죠. 비밀을 찾아보세요!
        </p>
      ),
      link_text: "도시어부 NFT 사러가기",
      on: false,
      link_url: "https://opensea.io/collection/cityangler",
    },

    {
      img: img_ww02,
      liClassName: "cont-box02",
      divClassName: "cont02",
      title: "마스터",
      desc: (
        <p>
          마스터 NFT가 있어야 낚시대회에 획득한 물고기를 제출 할 수 있어요.
          <br /> 하지만 NFT가 하나도 없어도 걱정 마세요.
          <br /> 낚시 시즌 1 오픈 기념으로 하루 1회 무면 낚시꾼이 도와드립니다!
          <br /> 누군지는 비밀이에요!
        </p>
      ),
      link_text: "마스터 NFT 사러가기",
      on: false,
      link_url: "https://opensea.io/collection/treasures-club-masters",
    },

    {
      img: img_ww03,
      liClassName: "cont-box03",
      divClassName: "cont03",
      title: "빈껍데기 공작부인",
      desc: (
        <p>
          빈껍데기 공작부인 NFT는 놓치기 아까운 훌륭한 조력자예요. <br />
          혹시 몰라요, 특별히 큰 녀석들을 끌어와 줄지도?!
        </p>
      ),
      link_text: "빈껍데기 공작부인 NFT 사러가기",
      on: false,
      link_url: "https://opensea.io/collection/treasureswebtoon-genesis-block",
    },

    {
      img: img_ww04,
      liClassName: "cont-box04",
      divClassName: "cont04",
      title: "특송",
      desc: (
        <p>
          안정적인 스페셜 특송 NFT 엔진으로 업그레이드 가능합니다. 보다 쉬운 낚시를 원하시는 분은
          지금 바로 업그레이드!
        </p>
      ),
      link_text: "특송 NFT 사러가기",
      on: false,
      link_url: "https://opensea.io/collection/treasurescine-genesis-block",
    },
  ]);

  const moveToGamePage = () => {
    const isLogin = JSON.parse(localStorage.getItem("isKaikasLogin")!);

    if (isWalletLogin && accountInfo) {
      window.open(
        `http://34.64.125.85/WaterWorld?WalletID=${accountInfo}`,
        "_blank",
        "noopener noreferrer"
      );
      return;
    }

    alert("지갑 로그인을 하신 후 실행 가능 합니다.");
  };

  return (
    <>
      <section className="kv" id="home">
        <div className="inner">
          <a className="logo-meta">
            <img src={logo_metaverse_img} alt="" />
          </a>
          <div
            className="earth earth01"
            onClick={() => {
              const el = document.querySelector("#waterworld");
              if (el) {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          />

          <div
            className="earth earth02 target-move"
            onClick={() => {
              const el = document.querySelector("#themePark");
              if (el) {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            <a href="#" className="land" />
            <a
              onClick={(e) => {
                e.stopPropagation();
                const el = document.querySelector("#treasures");
                if (el) {
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
              className="rocket target-move"
            />
          </div>

          <div
            className="earth earth03"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const el = document.querySelector("#next-world");
              if (el) {
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          />
        </div>
      </section>

      <section className="feature01" id="waterworld">
        <div className="feature01-1">
          <strong>Enjoy Your Fishing Trip! In the Water World</strong>
          <p>워터월드의 첫 파크, 낚시 파크에 대하여 자세히 알아보세요!</p>
          <span className="scroll">
            <em>Scroll down</em>
          </span>
        </div>

        <div className="feature01-2">
          <div className="inner">
            <figure className="bg">
              <img src={section02_img} alt="" />
            </figure>
            <div className="desc">
              <strong className="ico-warning">주의 : </strong>
              <p>
                도시어부 NFT를 제외한 마스터, 특송, 빈껍데기 공작부인 NFTs는 한 시즌당 1회만 사용할
                수 있습니다. 도시어부 NFT는 같은 지갑에서 한 시즌당 3회까지 사용이 가능합니다.
              </p>
              {/*<p>NFT 2차 거래에 유의하세요.</p>*/}
              {/*<HashLink to="/search#search" className="btn-round">*/}
              {/*  사용된 NFT 검색하러 가기*/}
              {/*</HashLink>*/}
            </div>
            <ul className="ww-link" onMouseLeave={() => {}}>
              {contBoxLists.map((item, i) => (
                <NftContBox
                  img={item.img}
                  liClassName={item.liClassName}
                  divClassName={item.divClassName}
                  title={item.title}
                  desc={item.desc}
                  link_text={item.link_text}
                  key={i}
                  on={item.on}
                  lists={contBoxLists}
                  setLists={setContBoxLists}
                  link_url={item.link_url}
                />
              ))}
            </ul>
            <div className="btn-box text-c">
              <a className="btn-gradi border" style={cursorPointerStyle} onClick={moveToGamePage}>
                Start
              </a>
            </div>
          </div>
        </div>

        <div className="mobile">
          <strong>
            Enjoy Your Fishing Trip! <i className="br">In the</i> Water World
          </strong>
          <p>워터월드의 첫 파크, 낚시 파크에 대하여 자세히 알아보세요!</p>
          <div className="slider-wrap">
            <ul className="slider">
              <li className="cont-box01">
                <div>
                  <div className="flex">
                    <figure>
                      <img src={img_ww01} alt="" />
                    </figure>
                    <strong>도시어부</strong>
                  </div>
                  <p>
                    도시어부 NFT는 낚시를 하는 바다에 신비한 효과를 부여 합니다. 물론 그 중 더
                    신비한 NFT도 있죠. 비밀을 찾아보세요!{" "}
                  </p>
                  <a href="https://opensea.io/collection/cityangler" target="_blank">
                    <a className="btn-round">도시어부 NFT 사러가기</a>
                  </a>
                </div>
              </li>
              <li className="cont-box02">
                <div>
                  <div className="flex">
                    <figure>
                      <img src={img_ww02} alt="" />
                    </figure>
                    <strong>마스터</strong>
                  </div>
                  <p>
                    마스터 NFT가 있어야 낚시대회에 획득한 물고기를 제출할 수 있어요. 하지만 NFT가
                    하나도 없어도 걱정하지 마세요. 낚시 시즌 1회 오픈 기념으로 하루 1회 무면
                    낚시꾼이 도와드립니다! 누군지는 비밀이에요!
                  </p>
                  <a
                    href="https://opensea.io/collection/treasures-club-masters"
                    target="_blank"
                    className="btn-round"
                  >
                    마스터 NFT 사러가기
                  </a>
                </div>
              </li>
              <li className="cont-box03">
                <div>
                  <div className="flex">
                    <figure>
                      <img src={img_ww03} alt="" />
                    </figure>
                    <strong>빈껍데기 공작부인</strong>
                  </div>
                  <p>
                    빈껍데기 공작부인 NFT는 놓치기 아까운 훌륭한 조력자예요. 혹시 몰라요, 특별히 큰
                    녀석들을 끌어와 줄지도?
                  </p>
                  <a
                    href="https://opensea.io/collection/treasureswebtoon-genesis-block"
                    target="_blank"
                    className="btn-round"
                  >
                    빈껍데기 공작부인 NFT 사러가기
                  </a>
                </div>
              </li>
              <li className="cont-box04">
                <div>
                  <div className="flex">
                    <figure>
                      <img src={img_ww04} alt="" />
                    </figure>
                    <strong>특송</strong>
                  </div>
                  <p>
                    여러분 낚싯배의 엔진을 안정적인 스페셜 특송 NFT 엔진으로 업그레이드 가능합니다.
                    보다 쉬운 낚시를 원하시는 분은 지금 바로 업그레이드!
                  </p>
                  <a
                    href="https://opensea.io/collection/treasurescine-genesis-block"
                    target="_blank"
                    className="btn-round"
                  >
                    특송 NFT 사러가기
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="bg">
            <img src={img_section02_m} alt="" />
            <div className="cont cont01" />
            <div className="cont cont02" />
            <div className="cont cont03" />
            <div className="cont cont04" />
          </div>
          <div className="btn-box text-c">
            <a className="btn-gradi border">Start</a>
          </div>
          <div className="desc">
            <strong className="ico-warning">주의 : </strong>
            <p>
              도시어부 NFT를 제외한 마스터, 특송, 빈껍데기 공작부인 NFTs는 한 시즌당 1회만 사용할 수
              있습니다. 도시어부 NFT는 같은 지갑에서 한 시즌당 3회까지 사용이 가능합니다.
            </p>
            {/*<p>NFT 거래에 유의하세요.</p>*/}
            {/*<HashLink to="/search#search" className="btn-round">*/}
            {/*  사용된 NFT 검색하러 가기*/}
            {/*</HashLink>*/}
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionOne;
