import { useState } from "react";
import produce from "immer";

const NftSearch = () => {
  const [nftCheckBox, setNftCheckBox] = useState([
    { text: "마스터", id: "nftType01", checked: false },
    { text: "도시어부", id: "nftType02", checked: false },
    { text: "특송", id: "nftType03", checked: false },
    { text: "빈껍데기 공작부인", id: "nftType04", checked: false },
  ]);

  return (
    <>
      <tr>
        <th>NFT별 검색</th>
        <td>
          <ul>
            {nftCheckBox.map((checkbox, i) => (
              <li key={i}>
                <span className="checkbox">
                  <input
                    type="checkbox"
                    id={checkbox.id}
                    checked={checkbox.checked}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const nextState = produce(nftCheckBox, (draft) => {
                        const findData = draft.find((box) => box.id === checkbox.id);
                        findData!.checked = checked;
                      });
                      setNftCheckBox(nextState);
                    }}
                  />
                  <label htmlFor={checkbox.id}>
                    <span />
                    {checkbox.text}
                  </label>
                </span>
              </li>
            ))}
          </ul>
        </td>
      </tr>
    </>
  );
};

export default NftSearch;
