// images
import logo_symbol_img from "@assets/images/logo_symbol.png";
import btn_nav_img from "@assets/images/btn_nav-menu.png";
import sns_discord_img from "@assets/images/ico_sns-discod.png";
import sns_twitter_img from "@assets/images/ico_sns-twitter.png";
import sns_insta_img from "@assets/images/icn-social-instagram@2x.png";
import btn_fold_img from "@assets/images/btn_fold.png";
import logo_tc_img from "@assets/images/logo_tc.png";
import logo_metaverse_img from "@assets/images/logo_metaverse.png";
import btn_water_world_img from "@assets/images/btn_water-world.png";
import ico_wallet_img from "@assets/images/ico_wallet.png";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { accountInfoAtom, isWalletLoginAtom } from "../../recoil/atom";
import { cursorPointerStyle } from "@styles/utils";
import { Link, useLocation } from "react-router-dom";
import { pathName } from "../../common/routes";
import classNames from "classnames";
import { HashLink } from "react-router-hash-link";
import OutsideClickHandler from "react-outside-click-handler";

const NavWrapper = styled.div<{ leftClick: boolean }>`
  .nav-unfold {
    left: ${(props) => (!props.leftClick ? "-110%" : "0%")};
    right: ${(props) => (!props.leftClick ? "-110%" : "0%")};
    transition: 0.3s;
  }
`;

const Navbar = () => {
  const [leftClick, setLeftClick] = useState(true);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isWalletLogin, setIsWalletLogin] = useRecoilState(isWalletLoginAtom);
  const location = useLocation();

  const { HOME, SEARCH } = pathName;

  const onDisconnectWallet = () => {
    setAccountInfo("");
    setIsWalletLogin(false);
    localStorage.setItem("isKaikasLogin", "false");
  };

  const onConnectWallet = async () => {
    if (typeof window.klaytn === "undefined") {
      alert("Kaikas 지갑을 설치해주세요.");
      return;
    }

    const accounts = await window.klaytn.enable();
    if (accounts.length) {
      setAccountInfo(accounts[0]);
      setIsWalletLogin(true);
      localStorage.setItem("isKaikasLogin", "true");
    }
  };

  const bootstrap = async () => {
    window.klaytn.on("accountsChanged", function (accounts: string[]) {
      // Time to reload your interface with accounts[0]!
      if (accounts.length) {
        setAccountInfo(accounts[0]);
      }
    });

    window.klaytn.on("networkChanged", function () {
      // `networkChanged` event is only useful when auto-refresh on network is disabled
      // Otherwise, Kaikas will auto-reload pages upon network change
      console.log("changed");
    });

    const isLogin = JSON.parse(localStorage.getItem("isKaikasLogin")!);
    if (isLogin) {
      const accounts = await window.klaytn.enable();
      if (accounts.length) {
        setAccountInfo(accounts[0]);
        setIsWalletLogin(true);
      }
    } else {
      setAccountInfo("");
      setIsWalletLogin(false);
    }
  };

  const moveToGamePage = () => {
    const isLogin = JSON.parse(localStorage.getItem("isKaikasLogin")!);
    console.log(isLogin, isWalletLogin, accountInfo);
    if (isWalletLogin && accountInfo) {
      window.open(
        `http://34.64.125.85/WaterWorld?WalletID=${accountInfo}`,
        "_blank",
        "noopener noreferrer"
      );
      return;
    }

    alert("지갑 로그인을 하신 후 실행 가능 합니다.");
  };

  const checkWalletUnlock = async () => {
    const isUnlocked = await window.klaytn._kaikas.isUnlocked();
    if (isUnlocked) {
      onDisconnectWallet();
    }
  };

  useEffect(() => {
    bootstrap();
  }, []);

  useEffect(() => {
    checkWalletUnlock();
  }, [location]);

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (leftClick) {
            setLeftClick(false);
          }
        }}
      >
        <NavWrapper leftClick={leftClick} className="nav-wrap">
          <span className="mobile">
            <Link to={HOME} className="logo">
              <img src={logo_symbol_img} alt="" />
            </Link>
            <a
              className="btn-menu"
              onClick={() => {
                setLeftClick(true);
              }}
            >
              <img src={btn_nav_img} alt="" />
            </a>
          </span>
          <div className="blur mobile" />
          <div className={classNames({ "nav-bar": true, line: location.pathname === "/search" })}>
            <h1 className="n-logo">
              <Link to={HOME}>
                <img src={logo_symbol_img} alt="" />
              </Link>
            </h1>
            <a
              className="btn-menu"
              style={cursorPointerStyle}
              onClick={() => {
                setLeftClick(true);
              }}
            >
              <img src={btn_nav_img} alt="" />
            </a>
            <ul className="sns">
              <li>
                <a href="https://discord.com/invite/x4AUBCVq2A" target="_blank">
                  <img src={sns_discord_img} alt="" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/_TreasuresClub" target="_blank">
                  <img src={sns_twitter_img} alt="" />
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/treasures_club" target="_blank">
                  <img src={sns_insta_img} alt="" />
                </a>
              </li>
            </ul>
          </div>
          <div className="nav-unfold">
            <div className="inner">
              <a
                className="btn-fold"
                onClick={() => {
                  setLeftClick(false);
                }}
              >
                <img src={btn_fold_img} alt="" />
              </a>
              <div className="logo">
                <div className="logo-tc">
                  <img src={logo_tc_img} alt="" />
                </div>
                <HashLink to="/#home" smooth>
                  <div className="logo-meta">
                    <img src={logo_metaverse_img} alt="" />
                  </div>
                </HashLink>
              </div>
              <div className="btn">
                <a onClick={moveToGamePage} className="btn-world" style={cursorPointerStyle}>
                  <img src={btn_water_world_img} alt="" />
                </a>
                {/*<HashLink*/}
                {/*  to="/search#search"*/}
                {/*  className="btn-search"*/}
                {/*  smooth*/}
                {/*  onClick={() => {*/}
                {/*    setLeftClick(false);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  사용 완료된 NFT 검색*/}
                {/*</HashLink>*/}
              </div>
              {!isWalletLogin ? (
                <div className="btn-connect" style={cursorPointerStyle}>
                  <a className="btn-round" onClick={onConnectWallet}>
                    Connect Wallet
                  </a>
                </div>
              ) : (
                <div className="btn-connect disconnect" style={cursorPointerStyle}>
                  <div className="flex wallet">
                    <span>
                      <img src={ico_wallet_img} alt="" />
                    </span>
                    <p>{accountInfo && accountInfo}</p>
                  </div>
                  <a className="btn-round" onClick={onDisconnectWallet}>
                    Disconnect
                  </a>
                </div>
              )}

              <ul className="gnb">
                <li className="ico01">
                  <HashLink to="/#waterworld" data-target="#waterworld" smooth>
                    워터 월드란?
                  </HashLink>
                </li>
                <li className="ico02">
                  <HashLink to="/#treasures" data-target="#treasures" smooth>
                    트레저스 클럽 소개
                  </HashLink>
                </li>
                <li className="ico03">
                  <HashLink to="/#themePark" data-target="#themePark" smooth>
                    메타버스 테마파크
                  </HashLink>
                </li>
                <li className="ico04">
                  <HashLink to="/#next-world" data-target="#next-world" smooth>
                    다음 월드를 기다리며..
                  </HashLink>
                </li>
              </ul>
              <div className="bottom">
                <ul className="sns">
                  <li>
                    <a href="https://discord.com/invite/x4AUBCVq2A" target="_blank">
                      <img src={sns_discord_img} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/_TreasuresClub" target="_blank">
                      <img src={sns_twitter_img} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/treasures_club" target="_blank">
                      <img src={sns_insta_img} alt="" />
                    </a>
                  </li>
                </ul>
                <p className="copy">
                  © Treasure Labs. All Rights Reserved.<em>Terms & Conditions | Privacy Policy</em>
                </p>
              </div>
            </div>
          </div>
        </NavWrapper>
      </OutsideClickHandler>
    </>
  );
};

export default Navbar;
