import { atom } from "recoil";

export const currentLangAtom = atom({
  key: "currentLanguage",
  default: "KOR",
});

export const isWalletLoginAtom = atom({
  key: "isWalletLogin",
  default: false,
});

export const accountInfoAtom = atom({
  key: "walletAddress",
  default: "",
});

export const isVisibleNFtBoxAtom = atom({
  key: "isVisibleNFtBox",
  default: false,
});

// export const isPossibleMint = atom({
//   key: 'isPossibleMintAtom',
//   default: true,
// });
//
// export const contractInfo = atom({
//   key: 'contractInfo',
//   default: {
//     contractAddress: '',
//   },
// });
