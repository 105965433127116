// images
import img_tc_web from "@assets/images/img_tc-web.png";
import img_tc_mobile from "@assets/images/img_tc-mobile.png";
import img_tc_logo from "@assets/images/img_tc-logo.png";
import img_nft01 from "@assets/images/img_nft01.png";
import img_nft02 from "@assets/images/img_nft02.png";
import img_nft03 from "@assets/images/img_nft03.png";
import img_nft04 from "@assets/images/img_nft04.png";
import img_nft05 from "@assets/images/img_nft05.png";
import img_nft06 from "@assets/images/img_nft06.png";
import img_nft07 from "@assets/images/img_nft07.png";
import img_nft08 from "@assets/images/img_nft08.png";

const SectionTwo = () => {
  return (
    <>
      <section className="feature02" id="treasures">
        <div className="inner">
          <figure className="img-tc-web">
            <span className="pc">
              <img src={img_tc_web} alt="" />
            </span>
            <span className="mobile">
              <img src={img_tc_mobile} alt="" />
            </span>
          </figure>
          <div className="flex">
            <figure className="img-tc-logo">
              <img src={img_tc_logo} alt="" />
            </figure>
            <div className="text">
              <strong>
                2021년 10월 부터 국내 NFT
                <i className="br">
                  최전방에서 출발한 트레져스클럽! 메타버스 테마파크를 새롭게 시작합니다!
                </i>
              </strong>
              <p>
                최초의 대량 제너레이팅 아트 MASTERS NFT로 국내 NFT 시장을 개척한 트레져스클럽!
                트레져스클럽은 든든한 빽커, NFT 홀더들의 응원과 사랑 덕분에 현재까지도 다양한 분야의
                기업들과 콜라보 NFT를 발행, 그리고 완판을 이어가고 있는데요. 이제는 국내외 유수의
                대기업들과 손을 잡으며 트레져스클럽만의 세계관을 탄탄하게 넓혀가고 있습니다.
              </p>
              <p>
                과감하게 새로운 도전을 거듭하는 Web 3.0 시대의 개척자라고 할 수 있죠. 특별한 홀더
                분들과 함께하기에 더욱 의미 있는 트레져스클럽의 항해에 함께 하세요.
              </p>
              <a href="https://treasuresclub.io/" target="_blank" className="btn-gradi">
                Treasures Club 더 자세히 알아보기
              </a>
            </div>
          </div>
          <ul>
            <li>
              <img src={img_nft01} alt="" />
            </li>
            <li>
              <img src={img_nft02} alt="" />
            </li>
            <li>
              <img src={img_nft03} alt="" />
            </li>
            <li>
              <img src={img_nft04} alt="" />
            </li>
            <li>
              <img src={img_nft05} alt="" />
            </li>
            <li>
              <img src={img_nft06} alt="" />
            </li>
            <li>
              <img src={img_nft07} alt="" />
            </li>
            <li>
              <img src={img_nft08} alt="" />
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default SectionTwo;
