import { cursorPointerStyle } from "@styles/utils";

const Footer = () => {
  return (
    <>
      <footer>
        <p className="copy">
          © Treasure Labs. All Rights Reserved. |{" "}
          <em
            style={cursorPointerStyle}
            onClick={() => {
              window.open(
                "https://sites.google.com/treasurelabs.io/water-world-terms-conditions/%ED%99%88",
                "_blank"
              );
            }}
          >
            Terms & Conditionsz | Privacy Policy
          </em>
        </p>
      </footer>
    </>
  );
};

export default Footer;
