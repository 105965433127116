import React, { FC } from "react";
import classNames from "classnames";

interface Props {
  img: string;
  isCompleted: boolean;
}

const NftLists:FC<Props> = ({ img, isCompleted}) => {
  return (
    <>
      <li className={classNames({ show: isCompleted })}>
        <figure>
          <img src={img} alt="" />
        </figure>
        { isCompleted && <div className="over">
          <div className="txt">
            <a>시즌 1 참여완료</a>
            <p>해당 시즌 참여가 더이상 불가능합니다.</p>
          </div>
        </div>
        }
      </li>
    </>
  )
};

export default React.memo(NftLists);