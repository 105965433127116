import SectionOne from "@components/HomeSection/SectionOne";
import SectionTwo from "@components/HomeSection/SectionTwo";
import SectionThree from "@components/HomeSection/SectionThree";
import SectionFour from "@components/HomeSection/SectionFour";
import Footer from "@components/Footer";
import { useEffect } from "react";
import $ from "jquery";

const HomePage = () => {
  return (
    <>
      <article>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
      </article>
      <Footer />
    </>
  );
};

export default HomePage;
