// images
import ico_theme01 from "@assets/images/FatasyWorld_icon.png";
import ico_theme02 from "@assets/images/SportsArena_Icon.png";
import ico_theme03 from "@assets/images/ShopingStreet_icon.png";
import ico_theme04 from "@assets/images/MordenCity_icon.png";
import ico_theme05 from "@assets/images/Next_Unreveal_Icon.png";

const SectionFour = () => {
  return (
    <>
      <section className="feature04" id="next-world">
        <h2 className="tit">
          <span>테마파크 확장 공사 중</span>
        </h2>
        <ul>
          <li>
            <figure>
              <img src={ico_theme01} alt="" />
            </figure>
            <strong>판타지 월드</strong>
            <p>
              아름다운 소환수와 마법의 세계, 트레져스 클럽의 판타지 월드가 곧 여러분의 눈앞에
              펼쳐집니다.
            </p>
          </li>
          <li>
            <figure>
              <img src={ico_theme02} alt="" />
            </figure>
            <strong>스포츠 아레나</strong>
            <p>
              함께 최선을 다한다면,
              <br /> 승패는 상관없이 뿌듯하고 즐거울거에요!
            </p>
          </li>
          <li>
            <figure>
              <img src={ico_theme03} alt="" />
            </figure>
            <strong>쇼핑 스트리트</strong>
            <p>
              Real Life와 Web 세계의
              <br /> 직접적인 연결! 잠들지 않는
              <br /> 쇼핑의 거리에서 만나요.
            </p>
          </li>
          <li>
            <figure>
              <img src={ico_theme04} alt="" />
            </figure>
            <strong>모던 시티</strong>
            <p>
              갑자기 귓가에 스포츠카 엔진의
              <br /> 굉음이 들려도, 출처를 알 수 없는
              <br /> 드론이 날아들어도 놀라거나
              <br /> 너무 걱정하지 마세요.
              <br /> 여기는 모든 것이 가능한
              <br /> 모던 시티니까요.
            </p>
          </li>
          <li>
            <figure>
              <img src={ico_theme05} alt="" />
            </figure>
            <strong>NEXT</strong>
            <p>
              과연 무면호🚀의
              <br /> 다음 행선지는 어디일까요?
            </p>
          </li>
        </ul>
      </section>
    </>
  );
};

export default SectionFour;
