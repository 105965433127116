import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import produce from "immer";
import { cursorPointerStyle } from "@styles/utils";

interface BaseProps {
  img: string;
  liClassName: string;
  divClassName: string;
  title: string;
  desc: JSX.Element;
  link_text: string;
  on: boolean;
  link_url: string;
}

interface Props extends BaseProps {
  lists: BaseProps[];
  setLists: Dispatch<SetStateAction<BaseProps[]>>;
}

const NftContBox: FC<Props> = ({
  img,
  desc,
  divClassName,
  title,
  liClassName,
  link_text,
  on,
  lists,
  setLists,
  link_url,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const onMouseOver = (e: any) => {
    // setIsVisible(true);
    const nextState = produce(lists, (draft) => {
      draft.map((item) => {
        if (item.title === title) {
          return (item!.on = true);
        }
        return (item!.on = false);
      });
    });
    setLists(nextState);
  };

  const onMouseLeave = () => {
    const nextState = produce(lists, (draft) => {
      draft.map((item) => {
        return (item!.on = false);
      });
    });
    setLists(nextState);
  };

  useEffect(() => {
    setIsVisible(on);
  });

  return (
    <div>
      <li className={classNames(liClassName, { on: isVisible })}>
        <div className={classNames("img-cont", divClassName)} onMouseOver={onMouseOver} />
        <div className="over-cont flex" onMouseLeave={onMouseLeave}>
          <figure>
            <img src={img} alt="" />
          </figure>
          <div className="text">
            <strong>{title}</strong>
            {desc}
            <a href={link_url} target="_blank" style={cursorPointerStyle} className="btn-round">
              {link_text}
            </a>
          </div>
        </div>
      </li>
    </div>
  );
};

export default NftContBox;
