import { Route, Routes } from "react-router-dom";
import { pathName } from "./routes";
import HomePage from "@pages/Home";
import SearchPage from "@pages/Search";

const PublicRouter = () => {
  const { HOME, SEARCH, RESULT } = pathName;

  return (
    <Routes>
      <Route path={HOME} element={<HomePage />} />
      <Route path={SEARCH} element={<SearchPage />} />
    </Routes>
  );
};

export default PublicRouter;
