import NftSearch from "@components/NftSearch";
import NftStatusSearch from "@components/NftStatusSearch";

import nft_img from "@assets/images/img_nft01.png";
import { FormEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { cursorPointerStyle } from "@styles/utils";
import { stringify } from "qs";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NftLists from "@components/NftLists";
import useInput from "@hooks/useInput";

const tabLists = ["All", "마스터", "도시어부", "특송", "빈껍데기 공작부인"];
const dummyData = [
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: true },
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: false },
  { img: nft_img, isCompleted: false },
];
const SearchPage = () => {
  const [currentTab, setCurrentTab] = useState("All");
  const [searchInput, onChangeSearchInput, setSearchInput] = useInput("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSearchPage, setIsSearchPage] = useState(false);
  const location = useLocation();
  const onSearch = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (!searchInput) {
      navigate("/search");
      return;
    }

    const params = { q: searchInput };

    navigate({
      pathname: "/search",
      search: `?${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    document.querySelector(".nav-wrap")!.scrollTo(0, 0);
    document.body.addEventListener("load", () => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <>
      <article id="search">
        <section className="ww-search-result">
          <div className="search-top">
            <div className="input-box">
              <form onSubmit={onSearch}>
                <input
                  type="text"
                  placeholder="Treasures Club"
                  style={{ outline: "none" }}
                  value={searchInput}
                  onChange={onChangeSearchInput}
                />
                <button style={{ display: "none " }} />
              </form>
            </div>
          </div>

          <div className="result-cont">
            <div className="type-check">
              <table>
                <tbody>
                  <NftSearch />
                  <NftStatusSearch />
                </tbody>
              </table>
            </div>
            <ul className="tab">
              {tabLists.map((tab, i) => (
                <li
                  key={i}
                  className={classNames({ curr: currentTab === tab })}
                  onClick={() => {
                    setCurrentTab(tab);
                  }}
                  style={cursorPointerStyle}
                >
                  <a>{tab}</a>
                </li>
              ))}
            </ul>
            <div className="cont-box">
              <div className="cont on">
                <ul className="result-list">
                  {dummyData.map((item, i) => (
                    <NftLists key={i} img={item.img} isCompleted={item.isCompleted} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </article>
    </>
  );
};

export default SearchPage;
