import React, { useState } from "react";
import produce from "immer";

const NftStatusSearch = () => {
  const [checkBox, setCheckBox] = useState([
    { text: "전체", checked: false, id: "situation01", disabled: false },
    { text: "참여가능", checked: false, id: "situation02", disabled: false },
    { text: "참여 중", checked: false, id: "situation03", disabled: false },
    { text: "참여 완료", checked: false, id: "situation04", disabled: false },
  ]);
  return (
    <>
      <tr>
        <th>상태별 검색</th>
        <td>
          <ul>
            {checkBox.map((item, i) => (
              <li key={i}>
                <span className="checkbox">
                  <input
                    type="checkbox"
                    id={item.id}
                    disabled={item.disabled}
                    checked={item.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked && item.text === "전체") {
                        const result = checkBox.map((box) => {
                          if (box.text === "전체") {
                            return { ...box, checked: true };
                          }
                          return { ...box, checked: true, disabled: true };
                        });
                        setCheckBox(result);
                        return;
                      }

                      if (!e.target.checked && item.text === "전체") {
                        const result = checkBox.map((box) => {
                          return { ...box, checked: false, disabled: false };
                        });
                        setCheckBox(result);

                        return;
                      }

                      const nextState = produce(checkBox, (draft) => {
                        const findData = draft.find((box) => box.text === item.text);
                        findData!.checked = e.target.checked;
                      });
                      setCheckBox(nextState);
                    }}
                  />
                  <label htmlFor={item.id}>
                    <span />
                    {item.text}
                  </label>
                </span>
              </li>
            ))}
          </ul>
        </td>
      </tr>
    </>
  );
};

export default NftStatusSearch;
