import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import PublicRouter from "./common/router";
import Navbar from "@components/Navbar";
import { useLocation } from "react-router-dom";

function App() {
  return (
    <main>
      <div id="contents">
        <Navbar />
        <PublicRouter />
      </div>
    </main>
  );
}

export default App;
